import { FC, ReactElement } from "react";
import { PhotoStripe } from "../components";
import { text } from "../texts";
import photo from "../assets/faq.jpg";

export const Questions: FC = (): ReactElement => {
  const { portada, faq } = text.questions;
  return (
    <div className="">
      <PhotoStripe photo={photo} text={portada} />
      <div
        className="accordion accordion-flush container"
        id="accordionFlushExample"
      >
        {faq.map(({ answer, question }, index) => (
          <div className="accordion-item" key={question}>
            <h2 className="accordion-header">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target={`#flush-collapse${index}`}
                aria-expanded="false"
                aria-controls={`flush-collapse${index}`}
              >
                {question}
              </button>
            </h2>
            <div
              id={`flush-collapse${index}`}
              className="accordion-collapse collapse"
              data-bs-parent="#accordionFlushExample"
            >
              <div className="accordion-body">{answer}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
