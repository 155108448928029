import { FC, ReactElement } from "react";
import { TextBody } from "./TextBody";
import "./Footer.css";

type FooterProps = {
  isHome: boolean;
};
export const Footer: FC<FooterProps> = ({ isHome }): ReactElement => {
  return (
    <>
      <TextBody isHome={isHome} />
      <div className="footer">
        ©2022 Red Psicólogos UBA. Todos los derechos reservados.
      </div>
    </>
  );
};
