import { FC, ReactElement } from "react";
import "./PhotoStripe.css";

type PhotoStripeProps = {
  children?: JSX.Element;
  height?: number;
  photo: string;
  text?: string;
};

export const PhotoStripe: FC<PhotoStripeProps> = ({
  text,
  photo,
  children,
  height,
}): ReactElement => {
  return (
    <div className="photo-stripe">
      {text && <h1 className="photo-stripe-text">{text}</h1>}
      <img
        className="photo-stripe-image"
        src={photo}
        alt={text}
        style={{ minHeight: height }}
      />
      {children}
    </div>
  );
};
