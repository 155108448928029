import { FC, ReactElement } from "react";
import { useNavigate } from "react-router-dom";
import { PhotoStripe } from "../components";
import { Url } from "../types";
import { text } from "../texts";
import photo from "../assets/news.jpg";
import "./News.css";

export const News: FC = (): ReactElement => {
  const navigate = useNavigate();
  const { portada, cards } = text.news;

  const navigateToArticle = (link: string) => {
    navigate(`/${Url.articles}/${link}`);
  };

  return (
    <>
      <PhotoStripe photo={photo} text={portada} />
      <div className="container">
        <div className="row row-cols-1 row-cols-md-3 g4">
          {cards.map(({ title, shortText, photo, link }) => (
            <div className="col p-0" key={photo}>
              <div className="card h-100">
                <img
                  src={`./assets/news-${photo}.jpg`}
                  className="cursor-pointer card-img-top"
                  onClick={() => navigateToArticle(link)}
                  alt={photo}
                />
                <div className="card-body">
                  <h2
                    onClick={() => navigateToArticle(link)}
                    className="cursor-pointer card-title violet-letter thin-letter"
                  >
                    {title}
                  </h2>
                  <p className="card-text">{shortText}</p>
                </div>{" "}
                <div className="card-footer">
                  <button
                    type="button"
                    className="btn btn-link"
                    onClick={() => navigateToArticle(link)}
                  >
                    Leer más
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
