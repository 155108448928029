import { FC, ReactElement } from "react";
import { Url } from "../types";
import logo from "../assets/LogoRED.svg";
import { useLocation, useNavigate } from "react-router-dom";
import "./NavBar.css";

export const NavBar: FC = (): ReactElement => {
  const navigate = useNavigate();
  const location = useLocation();
  const arrayOfUrls = Object.values(Url);
  const arrayOfTitles = [
    <span>Inicio</span>,
    <span>Quiénes&nbsp;Somos</span>,
    <span>Terapia online</span>,
    <span>Atención</span>,
    <span>Novedades</span>,
    <span>Preguntas</span>,
  ];

  const isActive = (url: Url) => {
    return location.pathname === `/${url}` ? "nav-bar-selected" : "";
  };

  return (
    <div className="navbar-container">
      <nav className="navbar navbar-expand-lg">
        <div className="container-fluid navbar-container-fluid">
          <img
            className="cursor-pointer navbar-logo"
            src={logo}
            alt="logo"
            onClick={() => navigate(Url.home)}
          />
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              {arrayOfTitles.map((url, index) => {
                return (
                  <li
                    className={`nav-item ${isActive(
                      arrayOfUrls[index]
                    )} cursor-pointer`}
                    key={arrayOfUrls[index]}
                    onClick={() => navigate(arrayOfUrls[index])}
                  >
                    <button
                      className="nav-link active nav-bar-button-option nav-bar-border-right"
                      aria-current="page"
                    >
                      {url}
                    </button>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};
