import { FC, ReactElement, useEffect, useRef, useState } from "react";
import { PhotoStripe, VioletStripe } from "../components";
import { text } from "../texts";
import photo from "../assets/whoWeAre.jpg";
import photo_2 from "../assets/whoWeAre-2.jpg";
import { Link } from "react-router-dom";
import "./WhoWeAre.css";

export const WhoWeAre: FC = (): ReactElement => {
  const ref = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState<number>(0);
  const { portada, title, members, extras, stuff } = text.whoWeAre;
  const elementHeight =
    (ref.current ? ref.current.getBoundingClientRect().height : 0) + 40;

  useEffect(() => {
    setHeight(elementHeight);
  }, [elementHeight]);

  useEffect(() => {
    window.addEventListener("resize", () => setHeight(elementHeight));
    return () => {
      window.removeEventListener("resize", () => setHeight(elementHeight));
    };
  });

  return (
    <>
      <PhotoStripe photo={photo} text={portada} />
      <VioletStripe text={title} />
      <div className="container">
        <h1 className="whoweare-title violet-letter thin-letter">
          {members.title}
        </h1>
        {members.professionals.map(
          ({ name, license, experience, photo, linkedin }) => (
            <div className="whoweare-professional-card" key={license}>
              <div>
                <img
                  className="whoweare-professional-card-photo"
                  src={`./assets/lic-${photo}.jpg`}
                  alt={name}
                />
              </div>
              <div>
                <div className="whoweare-professional-card-name-license">
                  <h2 className="violet-letter thin-letter">{name}</h2>
                  <h3 className="thin-letter">{license}</h3>
                </div>
                {experience.map((item) => (
                  <p key={item}>{item}</p>
                ))}
                {linkedin && (
                  <Link target="_blank" to={linkedin}>
                    <i className="bi bi-linkedin whoweare-linkedin-icon" />
                  </Link>
                )}
              </div>
            </div>
          )
        )}

        <h1 className="violet-letter thin-letter mt-5">{stuff.title}</h1>
        <ul>
          {stuff.people.map((person) => (
            <li key={person} className="light-grey-letter">
              <h3 className="thin-letter light-grey-letter">{person}</h3>
            </li>
          ))}
        </ul>
      </div>
      <PhotoStripe photo={photo_2} height={height}>
        <div ref={ref} className="whoweare-photo-stripe-text">
          <h1 className="whoweare-photo-stripe-text-title">{extras.title}</h1>
          <ul>
            {extras.items.map((item) => (
              <li key={item}>
                <h3 className="whoweare-photo-stripe-text-item thin-letter">
                  {item}
                </h3>
              </li>
            ))}
          </ul>
        </div>
      </PhotoStripe>
    </>
  );
};
