import { useState } from "react";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import { NavBar, Footer, Header } from "../components";
import {
  Articles,
  Attention,
  TherapyOnline,
  Home,
  News,
  Questions,
  WhoWeAre,
} from "../pages";
import { Url } from "../types";

export const RouterApp = () => {
  const [isHome, setIsHome] = useState<boolean>(false);
  return (
    <Router>
      <Header />
      <NavBar />
      <Routes>
        <Route
          path={Url.default}
          element={<Home setIsHome={setIsHome} />}
        ></Route>
        <Route path={Url.whoWeAre} element={<WhoWeAre />}></Route>
        <Route path={Url.attention} element={<Attention />}></Route>
        <Route path={Url.news} element={<News />}></Route>
        <Route path={Url.questions} element={<Questions />}></Route>
        <Route path={Url.onlineTherapy} element={<TherapyOnline />}></Route>
        <Route
          path={`${Url.articles}${Url.articleName}`}
          element={<Articles />}
        ></Route>
      </Routes>
      <Footer isHome={isHome} />
    </Router>
  );
};
