import { FC, ReactElement } from "react";
import "./Header.css";
export const Header: FC = (): ReactElement => {
  return (
    <div className="header">
      <p className="header-whatsapp violet-letter">
        <i className="bi bi-whatsapp violet-letter header-mail-icon"></i>
        <a target="_blank" rel="noreferrer" href="https://wa.me/5491153109465">
          +54 9 11 5310-9465
        </a>
      </p>
      <p className="header-mail">
        <i className="bi bi-envelope violet-letter header-mail-icon"></i>
        <a href="mailto: redpsicologosuba@gmail.com">
          redpsicologosuba@gmail.com
        </a>
      </p>
    </div>
  );
};
