export enum Url {
  home = "",
  whoWeAre = "quienes-somos",
  onlineTherapy = "terapia-online",
  attention = "atencion",
  news = "novedades",
  questions = "preguntas",
  articles = "articulos",
  articleName = "/:articleName",
  default = "/*",
}
