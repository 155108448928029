import { FC, ReactElement } from "react";
import { text } from "../texts";
import "./TextBody.css";

type TextBodyProps = {
  isHome: boolean;
};

export const TextBody: FC<TextBodyProps> = ({ isHome }): ReactElement => {
  const { text1, text2, text3, text4, title } = text.textBody;

  const setInfo = (): ReactElement =>
    isHome ? (
      <div className="text-body-long">
        <h1 className="violet-letter thin-letter">{title}</h1>
        <h3 className="roboto mt-3">{text1}</h3>
        <h3 className="roboto mt-3">{text2}</h3>
        <h3 className="roboto mt-3">
          {text3}{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://wa.me/5491153109465"
          >
            +54&nbsp;9&nbsp;11&nbsp;5310-9465
          </a>
        </h3>
        <h3 className="roboto">
          {text4}{" "}
          <a href="mailto: redpsicologosuba@gmail.com">
            redpsicologosuba@gmail.com
          </a>
        </h3>
      </div>
    ) : (
      <div className="text-body-short">
        <h2 className="footer-whatsapp">
          <i className="bi bi-whatsapp"></i>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://wa.me/5491153109465"
          >
            +54 9 11 5310-9465
          </a>
        </h2>
        <h2 className="footer-mail">
          <i className="bi bi-envelope"></i>
          <a href="mailto: redpsicologosuba@gmail.com">
            redpsicologosuba@gmail.com
          </a>
        </h2>
      </div>
    );

  return <div className="text-body">{setInfo()}</div>;
};
