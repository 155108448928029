import { FC, ReactElement } from "react";
import "./VioletStripe.css";

type VioletStripeProps = {
  text: string;
};

export const VioletStripe: FC<VioletStripeProps> = ({ text }): ReactElement => {
  return (
    <div className="violet-stripe">
      <h2 className="violet-stripe-text">{text}</h2>
    </div>
  );
};
