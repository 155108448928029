import { FC, ReactElement, useEffect, SetStateAction, Dispatch } from "react";
import { text } from "../texts";
import { PhotoStripe, VioletStripe } from "../components";
import photo from "../assets/home.jpg";
import "./Home.css";

type HomeProps = {
  setIsHome: Dispatch<SetStateAction<boolean>>;
};
export const Home: FC<HomeProps> = ({ setIsHome }): ReactElement => {
  useEffect(() => {
    setIsHome(true);

    return () => {
      setIsHome(false);
    };
  }, []);

  const { title } = text.home;
  return (
    <>
      <PhotoStripe photo={photo} />
      <VioletStripe text={title} />
    </>
  );
};
