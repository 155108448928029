import { ReactElement, FC } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { text } from "../texts";
import { Url } from "../types";
import "./Articles.css";

export const Articles: FC = (): ReactElement => {
  const { articleName } = useParams();
  const navigateTo = useNavigate();
  const { cards } = text.news;
  const article = cards.find(({ link }) => link === articleName);

  const returnToNews = () => {
    navigateTo(`../../${Url.news}`);
  };
  if (!article) {
    return <Navigate to={`/${Url.news}`} replace={true} />;
  }

  const { photo, title, fullText, autor, source } = article;

  const htmlFullText = () =>
    fullText.map((text, index) => {
      switch (text[0]) {
        case "i":
          return (
            <p className="articles-p" key={index}>
              <i>{text[1]}</i>
            </p>
          );
        case "b":
          return (
            <p className="articles-p" key={index}>
              <b>{text[1]}</b>
            </p>
          );
        case "h2":
          return (
            <h2 className="articles-h2 violet-letter thin-letter" key={index}>
              {text[1]}
            </h2>
          );
        case "h3":
          return (
            <h3 className="articles-h3 violet-letter thin-letter" key={index}>
              {text[1]}
            </h3>
          );
        case "li":
          return (
            <li className="articles-li mb-2 roboto" key={index}>
              {text[1]}
            </li>
          );
        case "photo":
          return (
            <div className="articles-photo-container" key={index}>
              <img src={text[1]} alt="article-pic" />
            </div>
          );
        default:
          return (
            <p className="articles-p" key={index}>
              {text}
            </p>
          );
      }
    });

  return (
    <div className="container">
      <h1 className="articles-title violet-letter thin-letter">
        <i
          onClick={returnToNews}
          className="bi bi-arrow-left-circle cursor-pointer"
        ></i>
        {title}
      </h1>
      <div className="articles-photo-container">
        <img src={`../assets/news-${photo}.jpg`} alt={photo} />
      </div>
      <div className="articles-body">{htmlFullText()}</div>
      <i>
        Autor: {autor} - {source}
      </i>
    </div>
  );
};
