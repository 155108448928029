import { FC, ReactElement } from "react";
import { text } from "../texts";
import { VioletStripe, PhotoStripe } from "../components";
import photo from "../assets/onlineTherapy.jpg";

export const TherapyOnline: FC = (): ReactElement => {
  const { portada, title, text1 } = text.online;
  return (
    <>
      <PhotoStripe photo={photo} text={portada} />
      <VioletStripe text={title} />
      <div className="container">
        <h3>{text1}</h3>
      </div>
    </>
  );
};
