import { FC, ReactElement } from "react";
import { PhotoStripe, VioletStripe } from "../components";
import { text } from "../texts";
import photo from "../assets/attention.jpg";
import "./Attention.css";

export const Attention: FC = (): ReactElement => {
  const { portada, title } = text.attention;
  const cards = text.attention.cards;
  return (
    <>
      <PhotoStripe photo={photo} text={portada} />
      <VioletStripe text={title} />
      <div className="container" key={photo}>
        {cards.map(({ photo, title, body }) => (
          <div className="attention-card" key={title}>
            <div>
              <img
                src={`./assets/attention-${photo}.jpg`}
                className="attention-card-photo"
                alt={title}
              />
            </div>
            <div>
              <div className="attention-card-text">
                <h1 className="attention-card-title">{title}</h1>
                <p className="attention-card-body roboto">{body}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};
