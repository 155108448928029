export const text = {
  home: {
    title: "No encontrás la salida... ¿Y si te ayudamos a construirla?",
  },
  textBody: {
    title: "¿Por qué Red Psicólogos UBA?",
    text1:
      "Nombramos nuestro equipo con las siglas de la facultad, ya que es un orgullo para nosotros ser egresados de una de las Universidades más prestigiosas de Latinoamérica.",
    text2:
      "Nos conocimos en nuestro recorrido hospitalario, práctica que creemos fundamental para la formación, y decidimos armar una red para brindar a los pacientes una atención basada en la escucha de la palabra, respetando sus tiempos y posibilitando descubrir los motivos del malestar que los aqueja, para poder hacer algo al respecto. ",
    text3:
      "Ofrecemos atención psicológica virtual y presencial para residentes en Argentina o en el exterior. Podes comunicarte con nosotros enviando un WhatsApp:",
    text4: "o al mail:",
  },
  whoWeAre: {
    portada: "Quiénes Somos",
    title: "Nuestro equipo de profesionales",
    members: {
      title: "Miembros de la Red",
      professionals: [
        {
          name: "Lic. Clara Casal",
          license: "M.N.: 53.750",
          experience: [
            "Lic. en Psicología de la Universidad de Buenos Aires",
            'Concurrencia en el Hospital General de Agudos "J. M. Penna"',
            "Coordinadora de Talleres para niños, adolescentes y padres",
            "Psicóloga clínica especialista en adultos y adolescentes",
          ],
          photo: 1,
          linkedin:
            "https://www.linkedin.com/in/clara-casal-59b46956/?trk=nav_responsive_tab_profile_pic",
        },
        {
          name: "Lic. Gabriela Luna",
          license: "M.N.: 56.720",
          experience: [
            "Lic. en Psicología de la Universidad de Buenos Aires",
            "Experiencia hospitalaria",
            "Psicologa PFA",
            "Especialista niños, adolescentes, adultos",
          ],
          photo: 3,
          linkedin:
            "https://www.linkedin.com/in/gabriela-luna-6932b9107/?trk=nav_responsive_tab_profile_pic",
        },
        {
          name: "Lic. Natalia Yasuda",
          license: "M.N.: 45.257",
          experience: [
            "Lic. Prof. en Psicología de la Universidad de Buenos Aires",
            "Psicóloga clínica. Especialista en clínica con niños, adolescentes y adultos",
            "Especialista en Evaluaciones psicológicas",
            "Maestría en Psicoanalisis U.B.A.",
          ],
          photo: 4,
          linkedin:
            "https://www.linkedin.com/in/natalia-in%C3%A9s-yasuda-0540054a/?trk=hp-identity-name",
        },
        {
          name: "Lic. Natalia Fernández",
          license: "M.N.: 43.374",
          experience: [
            "Lic. en Psicología de la Universidad de Buenos Aires",
            'Concurrencia en el Hospital General de Agudos "J. M. Penna"',
            "Posgrado en Técnicas Proyectivas, Universidad de Buenos Aires",
            "Docente Universitaria U.B.A.",
          ],
          photo: 2,
        },
      ],
    },
    stuff: {
      title: "Nuestros Profesionales",
      people: [
        "Lic. Daniel Andrés Gareca - M.N. 57.401",
        "Lic. María Fernanda San Miguel - M.N. 53.361",
        "Lic. Noelia Vega - M.N. 39.286",
      ],
    },
    extras: {
      title: "¿Qué tipo de tratamientos y especialidades abordamos?",
      items: [
        "Realizamos terapia online para pacientes de Argentina o del resto del mundo.",
        "Brindamos tratamiento psicológico a niños/as, adolescentes, adultos, parejas y familias",
        "Realizamos Psicodiagnósticos (laboral/educacional/clínico)",
        "Pericias Psicológicas",
        "Talleres de Educación Sexual para colegios y escuelas.",
      ],
    },
  },
  attention: {
    portada: "Atención",
    title: "Los servicios que ofrecemos",
    cards: [
      {
        photo: "online",
        title: "Terapia online",
        body: "Una nueva forma de recibir apoyo psicológico desde cualquier lugar del mundo es a partir de la terapia online.  Brindamos servicios de terapia en línea que están diseñados para proporcionar comodidad, accesibilidad y confidencialidad a pacientes de habla hispana en todo el mundo. Con horarios flexibles y la posibilidad de conectarse desde la privacidad de tu hogar, te ofrecemos una atención personalizada, utilizando diversas herramientas de comunicación.",
      },
      {
        photo: "kids",
        title: "Niños",
        body: "Generalmente son los padres, familiares o miembros de la institución escolar los que observan que un niño está atravesando un momento de dificultad o sufrimiento. En estos casos se hace necesario pedir ayuda consultando con un profesional que oriente a los padres y ayude a resolver la situación de malestar.",
      },
      {
        photo: "teens",
        title: "Adolescentes",
        body: "La adolescencia es un período de constantes cambios que impactan inevitablemente en la psiquis de todo individuo. Pueden emerger conflictos tales como: trastornos alimenticios, ansiedad desmedida, cambios de humor y problemáticas en relación a la sexualidad. La consulta con un profesional brinda un espacio de ayuda, escucha y reflexión que permite aliviar dicho sufrimiento.",
      },
      {
        photo: "adults",
        title: "Adultos",
        body: "El ritmo acelerado de vida y las exigencias de la sociedad moderna impiden que se genere un momento de reflexión acerca de las propias acciones y de la posibilidad de cambio. La complejidad de la vida adulta conlleva un constante desafío. La consulta con un profesional permite situar los motivos de angustia y encontrar una solución posible.",
      },
      {
        photo: "couples",
        title: "Pareja",
        body: "Pensamos la terapia de pareja como un espacio que abre la posibilidad de diálogo, un proceso terapéutico que permite repensar el vínculo y las dinámicas existentes, que tornándose conflictivas en determinados momentos no dejan vislumbrar una salida.",
      },
      {
        photo: "orientation",
        title: "Orientación Vocacional",
        body: "La elección de una carrera suele ser una decisión que genera ansiedad. Esto puede acontecer también en adultos que evalúan la posibilidad de cambiar su orientación, o definir una rama a la que volcarse. Entendemos a la Orientación Vocacional como un proceso en el cual uno llega a conocerse más profundamente. Contamos con entrevistas personales y en el caso de los adolescentes, con los padres, quienes podrán colaborar en la definición de su perfil y proyecto profesional.",
      },
      {
        photo: "diag",
        title: "Psicodiagnóstico",
        body: "Realizamos informes psicodiagnósticos para el área laboral, educacional y clínica. Brindamos el desarrollo de un informe integral, evaluando las distintas áreas de la personalidad de un sujeto.",
      },
    ],
  },
  news: {
    portada: "Novedades",
    cards: [
      {
        photo: "7",
        title:
          '"La realidad me agota": de la urna al diván, el impacto de las elecciones en los consultorios',
        shortText:
          "En 2023 no hubo espacio terapéutico en el que no se haya conversado, al menos una vez, de política. Los efectos de una campaña maratónica, según los especialistas.",
        link: "de-la-urna-al-divan",
        fullText: [
          "En 2023 no hubo espacio terapéutico en el que no se haya conversado, al menos una vez, de política.Los efectos de una campaña maratónica, según los especialistas.",
          '"La realidad social me tiene agotada", le dice una paciente a su psicóloga. Es que el año electoral que termina el domingo se hizo largo, maratónico, y estuvo atravesado siempre por la sorpresa. A la crisis económica se le sumó la irrupción de Javier Milei y la promesa de dinamitar todo. La angustia y la incertidumbre dominaron las consultas terapéuticas de los argentinos, una realidad que a muchos especialistas les trajo escenas de diván parecidas a las de fines de los \'90 y el 2001.',
          ["b", "De crisis y terapias, de los 90 al presente"],
          "En general, las crisis económicas y sociales impactan en el consultorio. Porque los argentinos tenemos incorporada la consulta psicoanalítica como una herramienta para enfrentar las crisis. En pandemia, en momentos de crisis, de grieta, actualmente, en Argentina los consultorios se llenan. Y esto tiene que ver con algo de la idiosincrasia”, comenta a Página|12 la licenciada en psicología Victoria Barrutia.",
          'Para la especialista, “muchos psicoanalistas” consideran "la construcción del psiquismo en el marco del ámbito cultural subjetivante”. Y que por eso, en las últimas semanas, tenía la intuición de que “el consultorio iba a estar atravesado por la temática política y económica”. "Este clima de incertidumbre, de dificultad de proyección, siempre cambiante, genera un fuerte efecto intenso en el psiquismo.Y si bien los argentinos nos subjetivamos de esta manera en particular, en los últimos tiempos han sido extremos”, agrega.',
          "Además, Barrutia, que en los años 90 trabajó de cerca con esa población a la que la sociología denominó como “nuevos pobres” —aquellos que por la políticas neoliberales fueron despojados de sus trabajos y quedaron marginados del mercado laboral—, señala que en la actualidad escucha “algo similar respecto al impacto traumático en lo social”, pero con algunos rasgos distintivos.",
          "“Veo algo de lo traumático y la necesidad de elaborar una realidad de alto impacto. La dificultad de elaborar la intensidad que la realidad genera y la angustia de incertidumbre de dónde quedar parado. Cómo reinsertarse. En el consultorio, por ejemplo, noté que en todas las sesiones apareció la palabra incertidumbre. Apareció la angustia frente a la imposibilidad de detectar qué va a suceder. Por supuesto, eso impacta de manera distinta en cada paciente. Y aparecen recursos distintos”, dice.",
          ["b", "De las PASO al balotaje"],
          "A esta incertidumbre que genera la crisis económica hay que sumarle los cimbronazos electorales que se dieron en las urnas. Casi nadie daba ganador a Javier Milei en las PASO, pero el candidato de La Libertad Avanza se impuso con poco margen. De igual manera, nadie pudo prever la ventaja de Massa en las elecciones generales, algo que para muchos representó un verdadero batacazo. Esto, como no podía ser de otra manera, tuvo su correlato en los consultorios.",
          "Natalia Yasuda, docente y licenciada en psicología, asegura que en la consulta se vivió la discusión política “respecto a la ansiedad y la angustia que generaba la incertidumbre, sobre todo después de las PASO”. Para ella, “hubo una situación sorpresiva donde no se esperaba que ciertos candidatos llegaran a un porcentaje alto, y eso generó ansiedad”.",
          'Yasuda señala además que en las elecciones generales notó “cierto alivio” en relación a cómo se dieron los resultados. "El hecho de que Sergio Massa obtuviera más votos generó expectativa de alivio y se notó un poco menos de ansiedad o de angustia, al menos", afirma.',
          "Ahora, a tan solo días del balotaje, la licenciada en psicología dice que “resurgió algo de la ansiedad, pero no tanto como después de las PASO”. “Yo creo que hay algo donde hay cierta, no sé si ilusión, que se puede estar generando, pero de que quizás no va a haber un cambio tan radical. Porque eso genera mucho temor. Entonces creo que hay cierta cuestión de, no te digo tranquilidad, porque sigue habiendo ansiedad, pero de que no estaba tan agudo como en las primarias”, finaliza Yasuda.",
          ["b", "De qué hablamos cuando hablamos de política"],
          "Por su lado, la licenciada Clara Casal se refiere a otro tema clave en este asunto, que es de qué se habla cuando se habla de política. “Uno busca que el paciente se escuche a sí mismo”, comienza Casal, en relación a esta inquietud. Para la especialista, la idea es que, de haber, el paciente pueda ver que se genera alguna “contradicción”.",
          "“No se trata de imponer o convencer al paciente, sino de que pueda escuchar sus propias contradicciones y ver qué puede hacer con eso. Me suele pasar que asumen mi posición política. No me lo preguntan. Pero hasta ahora es más lo que expresan ellos o la posición que manifiestan que lo que me preguntan. El tema es la subjetividad y cómo vive cada uno de manera distinta esta problemática del contexto actual”, añade",
          "Y finaliza: “Es necesario que el psicoanalista deje de lado su personalidad y su persona. Uno no la pone en juego con el paciente, bajo ningún punto de vista. Ni lo político ni la transmisión de ideales ni cuestiones morales: todo eso debe quedar por fuera. Y uno de los pilares fundamentales para que eso quede por fuera es el análisis propio que permita no contaminar el espacio del paciente con cuestiones propias”.",
        ],
        autor: "Dylan Resnik",
        source:
          "https://www.pagina12.com.ar/617314-la-realidad-me-agota-de-la-urna-al-divan-el-impacto-de-las-e",
      },
      {
        photo: "1",
        title: "¿De qué hablamos cuando hablamos de amor?",
        shortText:
          "El amor en psicoanálisis es un fenómeno paradójico. Aunque no menos que en la vida cotidiana, donde también se manifiesta de las maneras más extrañas. Es […]",
        link: "de-que-hablamos-cuando-hablamos-de-amor",
        fullText: [
          "El amor en psicoanálisis es un fenómeno paradójico. Aunque no menos que en la vida cotidiana, donde también se manifiesta de las maneras más extrañas. Es el caso de muchos varones que, desde niños, no han hecho más que expresar su gusto por una chica a partir de actitudes agresivas, como tirar del pelo, hasta el desprecio y la degradación; o bien el de muchachas que pueden fingir indiferencia o desinterés, para que el amor “no se note”.",
          "En la práctica del psicoanálisis también el amor se actualiza invocando a su contrario. No hay nada más lejos del amor en un análisis que el interés por la persona del analista. Estos episodios suelen ser un obstáculo (ni siquiera una resistencia), y están mucho más cerca de la sugestión (y, por lo tanto, de la idealización) que del análisis. En un análisis el amor suele presentarse a partir del reproche, la queja, el desinterés, etc. No son pocos los pacientes que, por este motivo, no quieren saber nada de la persona del analista e incluso, eventualmente, se alarman ante la posibilidad de encontrárselo en la calle u otro lugar que no sea el consultorio. Dicho de otra manera, aquél demuestra de este modo que, antes que una persona, es un objeto.",
          "Con un objeto se pueden hacer muchas cosas. Recuerdo el caso de una paciente que se enojó ante la posibilidad de que una amiga suya le pidiera mi teléfono; pero también el de otra mujer que enviaba a consultar conmigo a las más diversas personas de su entorno… como un modo de sostener otra queja celosa, esta vez respecto del tiempo que en las sesiones dedicaba a cada una. Un analista es un objeto que, incluso cuando se comparte, no se presta.",
          "Esta reducción de la persona al objeto es un rasgo propio del amor. Es un hábito distinguir en toda relación entre dos fases: la del enamoramiento y la del amor. En cierta medida, el enamoramiento implica la fascinación por el otro; mientras que el amor… ¡el amor muchas veces comienza luego de una primera pelea! Nada une más que el espanto. Incluso en algunas parejas, las fricciones funcionan como causa del erotismo (no sólo en la reconciliación). Es en el conflicto que la fascinación se convierte en pacto, y este tipo de lazo toma la forma del autoerotismo: el otro asume el lugar de una voz con la que se cuenta, una mirada furtiva u otro modo de satisfacción pulsional.",
          "Lo mismo ocurre en un análisis: a partir del momento en que un paciente ya no quiere saber más nada de su analista… puede ser que se dedique a espiarlo. Recuerdo el caso de una mujer que, en cierta ocasión, me solicitó amistad en Facebook. Decidí aceptarla, ya que poco genera más encanto que la sustracción. En la siguiente sesión relató el episodio, y me dijo: “Te eliminé. Tu vida es un embole”. Por cierto, más allá de la conclusión trivial de que la vida de un analista es como la de cualquiera, lo interesante es el lugar en que un analista puede quedar localizado: un objeto que “embola”, un resto aburrido que puede ser descartado, etc. Esta misma localización es la que puede apreciarse, discrecionalmente, en la manera en que cada paciente se despide: están aquellos que con rapidez responden al saludo como una orden, mientras que otros se regodean en busca de una última palabra, y otra más y otra más.",
          "En este punto es que surge una pregunta por la llamada “neutralidad” del analista. No se trata de que el analista no tenga ideas, o que se esconda de sus pacientes (siempre me pareció graciosa esa timidez defensiva entre colegas), sino de que en el tratamiento pueda hacer semblante de ese objeto que, en el amor, muchas veces, es el más odiado.",
          "Jacques Lacan acuñó una expresión para dar cuenta de este movimiento. “En ti más que tú”. El amor, en última instancia, siempre es una suerte de “mutilación” del otro. Por eso, podría decirse, ¡siempre cuesta tanto! El amor no se reconoce como un sentimiento transparente, sino a partir del rechazo del amor. O, dicho de otro modo, el amor se expresa a través del rechazo. En la queja respecto del amor, cada sujeto busca aferrarse a una posición narcisista contra el autoerotismo. Y aquí es donde los caminos del amor en la vida cotidiana y en el análisis se distancian: mientras que en aquella el reconocimiento se impone como una referencia (en la medida en que cada uno quiere ser amado por lo que es), en el análisis esta distancia no se produce, es lo que Gérard Pommier ha llamado un “amor al revés”; aquí es donde comienza lo que suele llamarse el “análisis de la transferencia” como resorte crucial del tratamiento, que no es de otra cosa sino el análisis del modo singular en que cada uno se relaciona con ese objeto que es una voz, una mirada, una desecho o nada más que una exigencia de presencia.",
          "Muchas veces los psicoanalistas que idealizan la noción de neutralidad, como si fuera una especie de pureza ideológica (que, hoy en día, se padece como justificación de una irresponsabilidad política que, como siempre, es funcional a las posiciones de derecha), son los que luego no están dispuestos a admitir ser despreciados, olvidados, etc. En fin, en nombre de la neutralidad se puede rechazar el amor. Esa puede ser la neurosis de un analista. El analista no puede pedir ser amado. Porque nadie puede pedir algo así.",
          "En definitiva, el análisis no es de otra cosa más que de la manera en que cada uno ama. Esta es una diferencia sustancial entre el psicoanálisis y otras ofertas terapéuticas. Mientras que estas apuntan a la adaptación o la solución de problemas que parecieran importantes, el psicoanálisis es una opción de tratamiento de lo que siempre es urgente: el amor.",
          "* Luciano Lutereau: Psicoanalista, doctor en Filosofía y doctor en Psicología por la UBA, donde trabaja como investigador y docente. Cocoordinador de la Licenciatura en Filosofía de UCES. Este artículo remite a ideas de su último libro Ya no hay hombres. Ensayos sobre la destitución masculina, publicado por editorial Galerna.",
        ],
        autor: "Por Luciano Lutereau ",
        source:
          "https://www.pagina12.com.ar/10127-de-que-hablamos-cuando-hablamos-de-amor",
      },
      {
        photo: "2",
        title: "El psicoanálisis en la era de la hiperconectividad",
        shortText:
          "El avance de la llamada “Era de la información” está modificando muchas prácticas culturales que hasta hace poco parecían afianzadas. ¿Con el psicoanálisis pasa lo mismo? […]",
        link: "el-psicoanálisis-en-la-era-de-la-hiperconectividad",
        fullText: [
          "El avance de la llamada “Era de la información” está modificando muchas prácticas culturales que hasta hace poco parecían afianzadas. ¿Con el psicoanálisis pasa lo mismo? ¿Cuál es el impacto que la hiperconectividad tiene en la práctica psicoanalítica?",
          "–El discurso analítico está enraizado en la hipermodernidad, y esto nos remite a la subjetividad de la época que, a partir de los avances de la ciencia y las técnicas que la soportan, inciden con sus innovaciones en los modos de gozar de los seres hablantes. En este sentido, trabajar sobre los hiperconectados nos lleva a interrogarnos una vez más sobre el quehacer del analista y su posición frente al inconsciente, a la interpretación, la transferencia y el acto. A elucidar las soluciones singulares que construyen los analizantes frente al aislamiento, la desconexión del mundo real, y los estragos subjetivos profundos que esto conlleva. Nos interesa debatir los modos en que el discurso analítico y la práctica analítica inciden en la relación del sujeto con la pulsión. Qué inventivas, qué descubrimientos, qué experiencias clínicas tenemos los analistas frente a lo real de los lazos virtuales.",
          "–Hace un tiempo, el psicoanalista Ricardo Rodulfo decía que hoy los pacientes van proponiendo formatos de consulta que se apoyan en lo no presencial, pero que muchas veces son propuestas que tensionan con el “aquí y ahora” que constituía a la situación analítica ortodoxa. ¿Cómo se lleva el psicoanálisis lacaniano con esas innovaciones? ¿Hay un límite para las variaciones que demandan las nuevas prácticas culturales o son ellas las que terminan adecuando a la práctica psicoanalítica?",
          "–El tema de las demandas me parece uno de los más sensibles de la práctica analítica. El primer Lacan ya decía “no responder a la demanda” y eso quería decir, entre otras cosas, hay que dejar hablar, hay que hacer hablar para poder llegar a lo que cada uno silencia. El psicoanálisis lacaniano es el que más ha innovado en los standards, sin embargo el avance de las tecnologías y las nuevas modalidades de goce nos exigen que demos una respuesta que, sin desconocer los recorridos, vaya más allá de la tradición. La hiperconectividad nos lleva a interrogarnos, por ejemplo, sobre los vínculos con el analista fuera de la sesión, Facebook, messenger, whatasapp. Sobre si es recomendable o no analizar por skype. Porque la intermediación de cualquiera de los gadgets dificulta el momento de pagar, cuando se deja oír un suspiro, un murmullo, un bostezo, cuando se hace preciso poner palabras en lugar de un silencio. Y en ese sentido, una cosa es reinventar el psicoanálisis para cada uno de los pacientes que nos consulta y otra es banalizar la práctica.",
          [
            "i",
            "–¿Cuál es el enfoque del psicoanálisis ante la dependencia compulsiva que generan el celular y las aplicaciones con interacción online? ¿Cómo se desactiva esa compulsión en un mundo que pondera la hiperconexión?",
            "–Por lo general, los primeros que sufren la dependencia compulsiva son los padres de los chicos que no se quieren levantar de “la compu” ni para ir al baño. Es evidente que la hiperconexión produce un tipo de satisfacción que anula cualquiera de las otras, como suele ocurrir con las adicciones. El dispositivo tiene la función de taponar algo, quizás la angustia, y cuando falta la angustia no se siente la necesidad de cambiar. Entonces su interrupción, porque por ejemplo se agotó la batería, produce aburrimiento, desgano. Falta el objeto en el bolsillo, como decía Lacan. Y no hay chance de desactivar esa compulsión, o cualquier otra, si el sujeto que es su propio clínico no la habita con sufrimiento sino con plena satisfacción.",
            "–Hace poco, un colega que tiene una muy buena formación en ciencias sociales y que puede explicar con solvencia la vida moderna, me mandó por whatsapp un video de 10 segundos en el que se ve a dos niños sentados en el piso y jugando con la compu. En un audio posterior, me dijo: “Esos son mis hijos. Están jugando online con pibes que están en México, Estados Unidos y Sudáfrica. Cuando yo era chico, mi viejo tenía referencias que le permitían saber cómo era el mundo en el que vivía, también le permitían saber lo que estaba bien y lo que estaba mal, y dónde debía poner un límite. Pero yo frente a esto no sé lo que está bien ni lo que está mal; y tampoco sé dónde debo poner un límite, básicamente porque no puedo abarcar ni comprender el mundo con el que interactúan”. ¿Cuánto afecta al psicoanálisis un mundo que, por la transformación cultural en curso, presenta tantas dificultades para ser descifrado y administrado?",
          ],
          "–En principio yo diría ¡cuánto afecta a las familias y en particular cuánto afecta a los padres; pero lo voy a decir en una forma menos equívoca: cuánto afecta al Padre. Porque hablamos de la declinación del padre, es decir de los ideales, de aquello que orientaba. Y en este punto hay que poner la mirada en la ciencia, porque la ciencia se ha inmiscuido en nuestras vidas. Digo esto porque una de las funciones que le era eminente al padre ha sido degradada por el discurso de la ciencia. Ese discurso, que nos ha llevado a la manipulación de la procreación, también hizo que muchos dispositivos tecnológicos escapen a la voz del padre. En este sentido es muy esclarecedor el planteo de Miller cuando se pregunta justamente por la relación de los niños y de los adolescentes con el saber. Antes, dice, el saber era depositado en los adultos y estaba mediado por esos seres hablantes que eran los educadores y los padres; pero en la actualidad está disponible a simple demanda de Google, Wikipedia o equivalentes, sin ninguna mediación. El saber era un objeto que había que ir a buscar al campo del Otro, había que extraerlo del Otro por vía de la seducción, de la obediencia o de la exigencia, lo que implicaba pasar por una estrategia con el deseo del Otro. Hoy el saber está en el bolsillo, no es más el objeto del Otro. Y su contrapartida es que los hombres ya no saben cómo ser padres, pasan de la completa permisividad a una rigidez inexorable. Es muy válido, entonces, preguntarse con Miller cuál es la incidencia del mundo virtual en esos saberes.",
          [
            "i",
            "–¿El campo psi experimenta alguna dificultad interpretativa o de desacompasamiento frente a algunos procesos subjetivos actuales, como el que perciben –por caso– las ciencias sociales respecto de ciertas desclasificaciones culturales de la época actual? ¿Cómo aborda la EOL esta situación epocal: lo discute en el interior de la organización, intercambia impresiones con otras escuelas del mundo psi, participa de espacios interdisciplinarios?",
            "–Si tomamos en cuenta la última enseñanza de Lacan y las innovaciones que posteriormente propuso Miller acerca de los Inclasificables en la clínica y las psicosis ordinarias, hemos avanzado. Esto nos permitió que una gran cantidad de pacientes que antes no eran tratables por el psicoanálisis tengan una chance, con suplencias efectivas, para su forclusión esencial. El tratamiento de las urgencias subjetivas y los nuevos síntomas que se presentan, impulsiones, neoconversiones no histéricas, son tratados por el psicoanálisis lacaniano en pie de igualdad.",
            "–Según un estudio realizado por la Universidad de Oxford, la palabra que más se usó mundialmente en 2015, no fue una palabra, fue un emoticón (el de la risa). Este y otros indicios marcan una fuerte hegemonía de la comunicación visual en detrimento de la cultura escrita. Como si nos estuviéramos desplazando progresivamente del patrón lógico (logos) que caracterizó a occidente durante más de 2500 años, hacia un patrón icónico que trastroca las narrativas con que damos cuenta del mundo de la vida. ¿Cuál es la percepción que tiene el psicoanálisis de este proceso? ¿Percibe que esta prevalencia de la imagen tiene alguna influencia en la constitución de la subjetividad –por ejemplo– de los niños?",
          ],
          "–Como dice Jorge Alemán, el psicoanálisis separa subjetividad de sujeto, en tanto el sujeto del inconsciente nunca es el resultado de una construcción histórica. Hecha esta aclaración, podemos preguntarnos: ¿De dónde obtienen poder las imágenes? Y la respuesta es: de las palabras. Todas la redes sociales, incluidas Instagram y Snapchat, están tramadas por los significantes, que son más puros porque en general prescinden del auxilio de la voz. Aun así es verdad que esas imágenes que pululan por doquier deben producir algún efecto en los niños. Si tomamos en cuenta a los llamados “nativos digitales”, podríamos tratar de captar que sucede con los adolescentes. Quizás la mayor incidencia del mundo virtual sea la fantasía de una especial extensión del universo de lo posible, de mundos posibles abiertos a todo tipo de imaginarios.",
          "–La “extimidad” es un concepto de la tradición lacaniana que refiere aquello que siendo propio e íntimo del sujeto genera un cierto extrañamiento por la externalidad de su procedencia. Pero hace poco, en el libro La intimidad como espectáculo, Paula Sibilia efectúa una nueva acepción para referir la tendencia a exhibir la intimidad que se desarrolló con las redes sociales. ¿Es posible establecer alguna relación entre la exteriorización de la intimidad que plantea Sibilia y esa idea de que “lo íntimo está en el exterior, como un cuerpo extraño” que plantea Jacques-Alain Miller?",
          "–Extimidad es una palabra inventada por Lacan y fue dicha en su seminario sobre la ética de 1958. La retoma Miller para hacer referencia al goce que al sujeto contemporáneo se le hace extraño. Lo más íntimo es lo más rechazado. Dicho esto, y si tenemos en cuenta que hay una erótica del espacio y una del tiempo, la del espacio perturba las relaciones de distancia, lo más próximo se vuelve distante y ese es el fundamento del Complejo de Edipo, ya que desde el punto de vista libidinal los objetos más próximos de la familia son los que se vuelven más inaccesibles. Lo mismo podría decirse del tiempo. Pero en la era digital la existencia con respecto al tiempo y al espacio cambia fundamentalmente. La evitación de los sujetos, encantados por las pantallas, respecto del encuentro de los cuerpos produce un mundo que podemos caracterizar como de “todos fóbicos”. Se quiere exhibir todo pero seguramente para esconder lo que menos se soporta ver. Se pretende decirlo todo porque, por estructura, no-todo puede decirse.",
          [
            "i",
            "–Se podría decir que el sujeto lacaniano –permítame una lectura libre y simplificadora– es un impulso incompleto o inesencial que se construye a través de los significantes que lo nombran y aquellos con los que se identifica. Desde esta perspectiva, ¿qué lectura hace de los desdoblamientos de sí mismos que realizan (sobre todo) los jóvenes en las redes sociales, a través de innumerables usuarios que muchas veces sostienen personalidades diferentes? ¿Han observado algunas consecuencias patológicas propias de estos desdoblamientos? ¿Qué relación tiene con eso que algunos autores llaman “sujeto multitudinario”?",
          ],
          "–Efectivamente el sujeto se mueve siguiendo el desplazamiento del significante, y éste lo agita. Y esa multiplicidad de usuarios en una sola persona, si no se trata de un fenómeno psicótico, es un simulacro (palabra que tiene la misma raíz que semblante: similis). Todo lo que es discursivo no puede plantearse sino como de simulacro (o semblantes) y son éstos los que intentan tratar lo real en juego. El semblante hace creer que hay algo allí donde no hay, y bien sabemos que a través de los fantasmas los sujetos pueden estar en cualquier parte y de cualquier manera. La vida digital con los avatares permite ver en la pantalla algo que sólo los sueños deja traslucir, un goce imaginario fundamentalmente narcisista, que repite sin cesar la misma caricatura. En todo caso podemos decir que esa multiplicidad de “yo” de los multiusuarios suelen demostrar un problema en las identificaciones, algo de lo que el psicoanálisis puede ocuparse en forma adecuada. Quizás podría decirse que esos individuos gozan de la posición del “ni-ni”.",
          [
            "i",
            "–¿Dónde queda el cuerpo en esta suerte de postergación o subordinación instrumental al que es sometido por la virtualización y las nuevas prácticas comunicacionales?",
          ],
          "–El cuerpo tal como lo propone Lacan a partir del estadio del espejo, su dimensión esencialmente imaginaria, es la que aparecería en juego en todas las plataformas virtuales. El cuerpo está en la foto de perfil, las selfies, los videos. En fin, es lo imaginario del cuerpo lo que está en juego. Para la realidad virtual no se trataría de algo distinto. Es la imagen del cuerpo, el avatar, a partir de la cual se organiza asimismo la experiencia del mundo. Pero es también Hillary Clinton parada en una tarima y un grupo de seguidoras de espaldas a ella, retratándose con ella de fondo. Parecen más un grupo de fans de una popstar que cuadros políticos o de gente que asume la política como causa de lo social-común. ¿Sólo se trata de un hecho narcisista? ¿Cual es la verdad que hay detrás de las selfies? Mi hipótesis es que estamos ante el fenómeno de los sujetos desinhibidos o neo-desinhibidos, desbrujulados, que ante la falta de los ideales, de la caída del padre, suplen con una imagen compuesta su cercanía a quien mejor los representa.",
          [
            "i",
            "–¿Cuál es el lugar del psicoanálisis, en un tiempo histórico que algunos autores llaman poshumanista?",
          ],
          "–Si el poshumanismo quiere decir del reino de las máquinas, el psicoanálisis no es un humanismo, ni nunca lo fue. Sin embargo puedo intentar responder a la pregunta en forma indirecta, de través de Alan Turing, el que produjo la famosa máquina que descifró el código Enigma de los nazis y creó la primera computadora. Turing planteaba que la inteligencia artificial es eficaz si una máquina logra no solamente pensar sino también engañar, hacerse pasar por humano. Este principio es el que usa el “captcha” cuando entramos en una página web y la máquina hace preguntas para definir si el otro es humano o es otra máquina (para pasar la barrera alcanza con leer algunos números de una foto o letras sueltas). Podemos seguir con la ciencia ficción desde Inteligencia artificial en un camino inverso hasta Neuromante de Gibson, el creador del cyberpunk, en donde las personas intentaban unir sus goces propios con los del otro con un gadget llamado simestim. El verdadero problema no radica ahí sino cuando los hombres empiezan a creerse que su cuerpo, especialmente su cerebro es una máquina, en especial una máquina de aprender. Es el problema que plantean las TCC (terapias cognitivas) cuando de esa manera desconocen in situ la dimensión del sujeto. Quizás podamos inventar un breve pero profundo slogan que dé cuenta de la diferencia que plantea el psicoanálisis para los seres hablantes: ¡Sujetos sí! ¡Máquinas jamás!",
        ],
        autor: "Fernando Peirone",
        source:
          "https://www.pagina12.com.ar/124-el-psicoanalisis-en-la-era-de-la-hiperconectividad",
      },
      {
        photo: "3",
        title: "¿Eliges siempre el mismo tipo de pareja?",
        shortText:
          "¿Por qué solemos elegir el mismo tipo de pareja sentimental? ¿Cuál es el denominador común que nos hace enamorar y escoger a una persona en particular […]",
        link: "eliges-siempre-el-mismo-tipo-de-pareja",
        fullText: [
          [
            "h3",
            "¿Por qué solemos elegir el mismo tipo de pareja sentimental?",
          ],
          [
            "b",
            "¿Cuál es el denominador común que nos hace enamorar y escoger a una persona en particular para que sea nuestra pareja?",
          ],
          "Esta pregunta parece muy simple, pero muchas personas afirman que realmente no saben el porqué escogen a una persona u otra. Dicen que quizá se guían al principio por unas determinadas características -físicas o no- que les llaman la atención o algún rasgo de personalidad o simplemente se guían por una intuición.",
          ["h2", "¿Eliges siempre el mismo tipo de pareja"],
          "Es curioso que muchas personas, tras romper con una relación insatisfactoria, caigan en una situación parecida una y otra vez al cabo del tiempo. Esta situación es debida a que hay un denominador común en estas relaciones, se enamoran de una persona muy similar a su ex pareja y eso lleva a repetir el mismo patrón. Por lo tanto, eso genera situaciones y conflictos muy parecidos en relaciones distintas -pero no tan diferentes las unas de las otras-.",
          "Los estudios científicos dicen que las personas tienden a relacionarse con sus parejas de forma parecida a como aprendieron a relacionarse con sus padres durante su infancia. Dependiendo de eso se puede encontrar un gran abanico de posibilidades relacionales. Si las relaciones con sus padres fueron positivas, sanas y satisfactorias tenderán a buscar parejas similares a sus padres -en la manera de relacionarse y comunicarse entre ellos-.",
          "En cambio, si las relaciones con los padres fueron más bien negativas, conflictivas e insanas, tienden a repetir esos patrones relacionales en futuras parejas. ¿Y por qué pasa eso?",
          ["h2", "La inseguridades que arrastramos desde niños"],
          "Eso se debe a que en las relaciones parentales se crearon unas inseguridades, unos miedos y unas necesidades emocionales que dejaron, de alguna manera, esa marca emocional que los suele acompañar a lo largo de la vida. Pueden buscar personas que aparentemente parezcan distintas a esas figuras, pero que inconscientemente tienen algo en común. Eso es debido a que intentan hacer mejor lo que los padres hicieron mal- o lo que podría mejorarse-.",
          "Son personas que al principio de una nueva relación se relacionan de manera positiva y sana. Pero que, ante alguna que otra dificultad o problema en pareja -que siempre aparecen con el tiempo- hacen que salgan a flote esas inseguridades y miedos. Eso les hace volverse absorbentes, desconfiados, distantes, etc., que es lo que aprendieron de la manera en la que se relacionaban con sus padres.",
          "Llegados a este momento se sienten decepcionados con su pareja, por ser completamente distintos a lo que ellos conocieron de esa persona en el inicio de esa relación. Y no es verdad que sean distintas personas -la del principio con la del final de la relación- sino que, en los inicios, se relacionaban de una manera más sana, más positiva y eso cambia cuando en uno de los dos integrantes o en ambos se activan esos miedos por alguna razón. Empiezan a relacionarse desde la inseguridad y el miedo, que eran los patrones que aprendieron y registraron en su infancia.",
          ["h2", "Tratando de no tropezar con la misma piedra"],
          "Hablamos de que se tiende a seguir los patrones que se aprendieron en la infancia, pero nadie dice que esos patrones no puedan modificarse. Si uno se da cuenta de que esos patrones lo llevan a ser infeliz con la elección de sus compañeros de viaje en la vida, deberá hacer alguna cosa para salir de esa situación. Con mayor o menor dificultad puede modificar algunas cosas para que esa reincidencia en la búsqueda de patrones erróneos de pareja varíe, se modifique y llegue a desaparecer.",
          [
            "b",
            "¿Cómo podríamos cambiar esos patrones reincidentes y problemáticos? Para salir de esa reincidencia en la búsqueda de patrones de relaciones complicadas tenemos que cumplir los siguientes puntos:",
          ],
          ["h3", "1. Identificar nuestros miedos"],
          "Pensar qué nos hace más miedo cuando estamos en una relación y pensar por qué puede que nos sintamos así (relaciones parentales en la infancia, alguna ruptura amorosa no superada, etc.).",
          [
            "h3",
            "2. Semejanzas entre las relaciones que has tenido y cuáles son los problemas que tiendes a vivir con tus parejas",
          ],
          "De esta manera identificarás cuales son las cosas que tienes que trabajar de manera individual.",
          ["h3", "3. Superar los miedos"],
          "No tener miedo a que sucedan las cosas antes de que sucedan. Pero que no sean esos miedos los que te lleven a propiciar situaciones que te hagan sentirte incómodo/a o infeliz.",
          [
            "h3",
            "4. Tener confianza en ti mismo/a y valorarse (conocerse a uno mismo)",
          ],
          "Tenemos que tener en mente que toda persona tiene una serie de virtudes y defectos (en mayor o menor medida). Ser consciente de ello te puede hacer valorar tus actitudes y comportamientos. Esos comportamientos se pueden trabajar y potenciar. No debes pensar que tu felicidad depende de la persona que tienes a tu lado (que ayuda o la potencia) pero tú mismo debes sentirte bien y feliz por ti solo.",
          ["h3", "5. Ampliar horizontes"],
          "Descubrir que hay personas interesantes que salen de “los patrones en que te sueles fijar” y que te pueden aportar muchas cosas. Ampliar el tipo de persona en la que te sueles fijar, tanto a nivel físico como a nivel personal.",
        ],
        autor: "Xantal Sinapsis",
        source:
          "https://psicologiaymente.com/pareja/eliges-siempre-mismo-tipo-de-pareja",
      },
      {
        photo: "4",
        title: "Las 30 señales de maltrato psicológico en una relación",
        shortText:
          "En ocasiones puede ser difícil identificar qué conductas constituyen un abuso psicológico. En mis consultas, cuando observo indicios de que se pueda estar dando una situación […]",
        link: "las-30-señales-de-maltrato-psicologico-en-una-relacion",
        fullText: [
          [
            "h3",
            "En ocasiones puede ser difícil identificar qué conductas constituyen un abuso psicológico.",
            "En mis consultas, cuando observo indicios de que se pueda estar dando una situación de maltrato psicológico y emocional, suelo usar una serie de preguntas que hago que se lleven a casa como tarea.",
          ],
          "El objetivo es que mis pacientes valoren cuantos ítems de esa lista se cumplen, solo durante la semana que queda hasta la siguiente sesión. Han de ir anotando al lado de cada pregunta, las situaciones y fechas en las que se producen esos comportamientos para así crear un registro de las dimensiones del problema.",
          ["h2", "Identificar el maltrato psicológico camuflado"],
          "Es llamativo que un alto porcentaje de mis pacientes, al llegar a la tercera o cuarta cita, acuden con un elevado grado de ansiedad, ya que se hacen conscientes de la situación de maltrato psicológico en la que se encuentran, y la mayoría de los criterios los asumían como cuestiones «normales» que se dan en una relación de pareja.",
          "Este es uno de los aspectos más dañinos del maltrato psicológico: como no es maltrato físico, es más difícil de detectar y de identificar como algo que no debería darse en una relación sana. Es por eso que es muy necesario tomarse un tiempo en reflexionar sobre hasta qué punto estamos viviendo o reproduciendo formas de maltrato psicológico.",
          [
            "h2",
            "Las 30 señales de que tu pareja puede estar maltratándote psicológicamente",
          ],
          "Este es un breve listado de tipos de comportamiento que sirven para identificar los casos de maltrato psicológico en una pareja. Están planteados en forma de preguntas para que sean más accesibles y fáciles de relacionar con las vivencias de cada persona.",
          "1. ¿Controla el dinero que gastas? ¿tienes que pedirle el dinero a tu pareja? ¿pides permiso a la hora de comprar algo, ya sea para ti o para la casa?",
          "2. ¿Te dice cómo tienes que vestirte? ¿si vas de alguna manera que no le gusta se enfada contigo por ello y decides cambiarte de ropa? ¿Hay prendas que ya no te pones porque sabes que a no le gusta que vayas así y vas a tener problemas por ello?",
          "3. ¿Se enfada si inviertes más tiempo en tus amistades o familiares del que considera necesario?",
          "4. ¿Mantienes relaciones sexuales aunque no te apetezcan porque si no, se enfada?",
          "5. Contabiliza las veces que haces cosas que no te apetecen o con las que no estás de acuerdo para evitar una discusión.",
          "6. ¿Controla tu móvil y tus redes sociales?",
          "7. ¿Tienes que informarle de tus horarios?",
          "8. ¿Le quita importancia a tus logros personales o profesionales?",
          "9. Cuando haces algo por tu pareja, ¿te lo agradece o te hace sentir que es tu obligación?",
          "10. ¿Organiza tu tiempo libre? ¿Sientes que en los ratos de ocio tienes que consultarle en que invertir tu tiempo?",
          "11. ¿Cuando tienes algún problema lo minimiza con comentarios del tipo: eso no es nada, te quejas de vicio, etc.?",
          "12. ¿Cuando se ha dado una discusión, en la mayoría de las ocasiones cedes tú aún teniendo la razón porque podría pasarse días sin hablarte y haciéndote el vacío?",
          "13. ¿Si tiene un problema fuera del ámbito de la pareja, te hace sentir responsable de ello?",
          "14. ¿Te hace sentir que no sabrías seguir adelante si no estuvieras a su lado?",
          "15. ¿Te sientes culpable cuando enfermas?",
          "16. Si estáis en público, ¿temes decir lo que opinas por si acaso te trae consecuencias con tu pareja?",
          "17. ¿Usa el chantaje emocional a menudo para lograr sus objetivos?",
          "18. ¿Te recuerda una y mil veces los errores que has cometido?",
          "19. ¿Has dejado de contar tus problemas de pareja a tu entorno porque sabes que si se enterase se enfadaría?",
          "20. ¿Temes como decirle algunas cosas porque sabes que su reacción puede ser desproporcionada?",
          "21. ¿Notas que cuando un mismo hecho lo realiza otra persona lo valora más positivamente que si eres tú quien lo realiza?",
          "22. ¿Te sientes incómodo/a si te mira alguien del sexo opuesto por si acaso tu pareja se diera cuenta y pudiera ser motivo de otra discusión?",
          "23. ¿Sientes que necesitas su aprobación en cada cosa que haces, o incluso piensas?",
          "24. ¿La manera en la que se dirige a ti ha cambiado transformándose en imperativa?",
          "25. ¿Sientes que no puedes ser tú mismo/a cuando estás con tu pareja?",
          "26. Ligada a la anterior, ¿sientes que aún sin que esté, cuando quieres ser tú mismo/a piensas en que tal vez le moleste y dejas de hacer las cosas que querías?",
          "27. ¿Te trata como si fuera tu padre/madre en lugar de tu pareja?",
          "28. ¿Las decisiones importantes las toma sin tener en cuenta tu opinión?",
          "29. ¿Te hace dudar de tus capacidades?",
          "30. ¿Sientes miedo?",
          ["h2", "Consecuencias del maltrato emocional y psicológico"],
          "Una vez realizada la tarea, y una vez en consulta, valoro con las víctimas las consecuencias de este maltrato psicológico, que suelen ser estas:",
          ["li", "Malestar físico"],
          ["li", "Baja autoestima"],
          [
            "li",
            "Pérdida de relaciones sociales llevando en muchas ocasiones al aislamiento",
          ],
          ["li", "Sensación de haber dejado de ser la persona que era"],
          ["li", "Estrés"],
          ["li", "Ansiedad"],
          ["li", "Estado de ánimo deprimido"],
          ["li", "Alteraciones de los patrones de sueño"],
          ["li", "Problemas en la alimentación"],
          [
            "li",
            "Adicción a distintos tipos de sustancias (en las que cabe destacar las benzodiacepinas y el alcohol)",
          ],
          ["li", "Dejadez y descuido en el aspecto físico"],
          ["li", "Irritabilidad"],
          ["li", "Apatía"],
          ["li", "Sentimientos de impotencia e inutilidad"],
          ["li", "Indecisión"],
          ["li", "Inseguridad"],
          ["li", "Dependencia emocional"],
          ["li", "Ataques de ira dirigidos a otras personas"],
          ["li", "Inapetencia sexual"],
          ["li", "Sentimientos de vergüenza y culpa"],
          ["li", "Sensación de debilidad"],
          ["li", "Dificultad en la toma de decisiones"],
          ["li", "Culpabilidad"],
          ["li", "Mecanismos de afrontamiento basados en la huida"],
          ["li", "Sentimientos de inferioridad"],
          "A su vez, estos efectos del maltrato psicológico hacen que el clima dentro de la relación de pareja siga empeorando aún más, lo cual acarrea consecuencias graves para la víctima.",
          [
            "h2",
            "Tomando conciencia para poder abandonar las dinámicas de maltrato",
          ],
          [
            "b",
            "El primer paso para hacer frente al maltrato psicológico es saber identificar sus señales, algo difícil ya que son dinámicas no se producen de un día para otro y son señales sutiles de las que apenas nos damos cuenta.",
          ],
          "La intención de este artículo es poder llegar a ser conscientes de ello, y si nos sentimos identificados/as poder ser capaces de romper con la dependencia emocional que nos genera. El primer paso es poder identificar la existencia del maltrato psicológico para asumir la idea de que se han de producir cambios muy drásticos.",
        ],
        autor: "Paula Marín Fernández",
        source:
          "https://psicologiaymente.com/coach/salir-zona-de-confort-claves",
      },
      {
        photo: "5",
        title: "¿Cómo salir de tu zona de confort? 7 claves para lograrlo",
        shortText:
          "Si estás estancado en una vida gris, es posible que vivas en una burbuja llamada «zona de confort». A grandes rasgos, la zona de confort es […]",
        link: "como-salir-de-tu-zona-de-confort",
        fullText: [
          [
            "h3",
            "Si estás estancado en una vida gris, es posible que vivas en una burbuja llamada «zona de confort».",
          ],
          "A grandes rasgos, la zona de confort es un estado mental que no permite el crecimiento personal y todos aquellos fenómenos vinculados con el aprendizaje de conductas que nos hacen lograr un alto grado de satisfacción con el modo en el que se vive la vida.",
          "Aunque esta zona de confort pueda parecer agradable (ya que nos permite seguir siempre con el «piloto automático» puesto), no es un lugar suficientemente estimulante y a la larga no salir de ella puede hacer que nos sintamos vacíos y se caiga en la apatía o en ciertas dinámicas de comportamiento relacionadas con la depresión.",
          "Entre tú y yo… ¿qué es lo que te mantiene ahí anclado? Seguro que has deseado dar un paso al frente, ser valiente y comerte el mundo, pero no lo has hecho. Puedes estar sufriendo pero no mueves un dedo porque estás en tu zona de confort. Si te da miedo y te produce ansiedad abandonar el pasado para luchar por lo que siempre has soñado, no seas tan duro contigo mismo y sal de la zona de confort. ¡Verás que un mundo nuevo e ilusionante te espera!",
          ["h2", "Motivos por los que debes salir de tu zona de confort"],
          "¿Quieres más motivos para abandonar la rutina gris y salir ahí fuera a perseguir tus sueños? Aquí te dejo seis motivos por los que deberías plantearte salir de la zona de confort.",
          ["h3", "1. Te hará más fuerte como persona"],
          "Es posible que salir de la zona de confort te asuste o te produzca ansiedad, pero plántale cara al miedo y verás que no era para tanto. Sentir inseguridad es algo natural e incluso nos advierte de que no será fácil el camino. Pero cuando el miedo te inmoviliza, entonces se convierte en un problema que no te va a dejar desarrollar tu verdadero potencial. Empieza reconociendo que te asusta el futuro incierto, pero adopta una mentalidad positiva al respecto.",
          "Permítete caer de vez en cuando y míralo como una parte más del crecimiento como persona. Si sales de la zona de confort y disfrutarás del aprendizaje, con el tiempo podrás mirar atrás y sentirte orgulloso de ti mismo. Tu desarrollo personal empieza aquí, así que piensa en eso que te motiva, ¿realmente no merece la pena dar un pasito al frente y luchar por tus sueños?",
          ["h3", "2. Te hará ser más creativo"],
          "Salir de la zona de confort estimula la creatividad al conocer nuevas posibilidades y romper con lo que es rutinario. Un estudio publicado en Applied Cognitive Psychology (2012) demostró que los estudiantes que pasaron un semestre fuera de su país tuvieron puntuaciones más altas en dos pruebas de creatividad que aquellos sujetos que no habían estudiado fuera.",
          "Por otro lado, otro estudio reveló que el rasgo de personalidad de “apertura de a la experiencia”, uno de los 5 grandes, es el mejor predictor de logro creativo. Este rasgo de personalidad es característico de los individuos que suelen asumir riesgos, que se desafían a sí mismos o que prueban cosas nuevas constantemente.",
          ["h3", "3. Te permitirá ganar autoconfianza"],
          "Salir de la zona de confort y dar los pasos necesarios para conseguir tus objetivos va a tener efectos positivos en tus creencias de autoeficacia (o autoconfianza), es decir, en la percepción que tienes sobre si serás capaz de alcanzar tus metas. Este concepto lo introdujo el canadiense Albert Bandura, uno de los psicólogos más reconocidos y que elaboró la Teoría del Aprendizaje Social: que explica la regulación de la motivación y la acción humana.",
          "Por tanto, salir de la zona de confort y ponerte a prueba te hará ver con tus propios ojos que dominas ciertas habilidades que te van a permitir salir airoso de situaciones que pueden parecer complicadas a priori. Una percepción de autoeficacia positiva está asociada a pensamientos y aspiraciones positivas acerca de una conducta exitosa, menor estrés, ansiedad y percepción de amenaza, junto con una adecuada planificación del curso de acción y anticipación de buenos resultados.",
          ["h3", "4. Te ayudará a seguir con tu desarrollo personal"],
          "De jóvenes somos más propensos a arriesgarnos, pero a medida que nos hacemos mayores aprendemos a temer al fracaso, pudiendo llegar a la indefensión aprendida y a la paralización (¿conoces la «parálisis del análisis»?). Esto puede suponer un coste emocional serio si no cambiamos la manera de pensar, pues puede ser un impedimento para seguir con nuestra transformación hacia un mayor bienestar.",
          "En su libro “Auto-renovación”, el escritor John Gardner afirma: “Pagamos un precio muy alto por nuestros fracasos y es un obstáculo enorme en nuestro crecimiento. Esto provoca que nuestra personalidad no se desarrolle y no permite la exploración y la experimentación. No hay aprendizaje sin algo de dificultad. Si quieres seguir creciendo, debes superar el miedo al fracaso”.",
          [
            "h3",
            "5. Conocerás a gente nueva y vivirás nuevas experiencias gratificantes",
          ],
          "Salir de la zona de confort te permite tener nuevas experiencias, hacer actividades que no estaban dentro de tu agenda y conocer a gente nueva. Esto te va a permitir tener una vida más placentera y te va a permitir hacer nuevas amistades. Incluso puede que conozcas al amor de tu vida por salir de la zona de confort.",
          ["h3", "6. Los nuevos retos pueden hacerte envejecer mejor"],
          "Un estudio llevado a cabo en 2013 encontró que aprender nuevas habilidades mientras se mantiene una red social fuerte nos ayuda a conservar una buena agudeza mental a medida que envejecemos.",
          "La directora del estudio Denise Park, investigadora de la Universidad de Texas en Estados Unidos concluyó: “Parece ser que salir a realizar actividades no es suficiente. Es importante salir y realizar actividades que no sean familiares y que sean mentalmente desafiantes, pues proporcionan una gran estimulación tanto a nivel mental como social. Cuando estas dentro de la zona de confort es posible que estés fuera de la zona de mejora”.",
          ["photo", "../assets/articles-1.jpg"],
          ["h2", "Algunas recomendaciones para lograrlo"],
          "Desde luego, es muy fácil decirlo pero bastante más complicado hacerlo. Sin embargo, y aunque salir de la zona de confort sea complicado por la propia definición de este concepto, no es misión imposible. Si necesitas algo de ayuda o claves para salir de la zona de confort, puedes seguir estas pautas de comportamiento.",
          ["h3", "1. Desafíate y rinde al máximo"],
          "Salir de la zona de confort es un aspecto importante en el crecimiento personal, pues es no es posible pensar que llegaremos al lugar que queremos llegar haciendo lo justo y necesario y estando inmersos en la misma rutina de siempre.",
          "Alcanzar nuevas cimas supone el riesgo de hacer cosas que no se nos dan tan bien o que nos provocan cierto miedo o inseguridad (al menos al principio). Según un estudio llevado a cabo por un grupo de psicólogos, un poco de ansiedad puede ser positivo para mejorar nuestro rendimiento y nos permite seguir creciendo profesionalmente. Por tanto, convierte esas situaciones que te provocan ansiedad en situaciones estimulantes y que nadie te pare los pies. No le llames nervios o inseguridad, llámale «emoción».",
          [
            "h3",
            "2. Piensa en tu estilo de hacer las cosas… y actúa en el sentido opuesto",
          ],
          "Busca las situaciones que puedan obligarte a salir de tu zona de confort. Experimenta con estilos de baile que nunca asociarías con tu manera de ser, involúcrate en proyectos creativos que te fuercen a pensar de manera diferente, para experimentar contextos desafiantes a los que adaptarte. Si te propones gestionar estos cambios, y estos no son excesivamente extremos, esto hará que te vuelvas más flexible y asumas mejor la variabilidad y los cambios.",
          ["h3", "3. Busca cambios materiales en las cosas que te rodean"],
          "Para hacer cosas nuevas a las que no se está acostumbrado, nada como exponerse a ambientes nuevos. Físicamente novedosos, literalmente. La clave está en proponerte el objetivo de exponerte a situaciones novedosas haciendo que los espacios en los que te encuentras sean otros. Anímate a cambiar tu manera de vestir o la decoración de tu casa, muévete por entornos distintos y, por supuesto, viaja todo lo que puedas o incluso vete a vivir a otro lugar, aunque sea durante un tiempo. Esto conseguirá en ti un efecto similar al del consejo anterior, sólo que en este caso, en vez de variar las situaciones cuyo significado no asociarías con tu persona, harás lo mismo con espacios físicos.",
          ["h3", "4. Anticipa todas las excusas que te vas a poner"],
          "Sé consciente de que, cuando te fijes metas que te resulten incómodas dentro de tu zona de confort, inconscientemente vas a estar buscando un montón de excusas para no hacerlo. Juzga estas excusas como lo que son: invenciones cuyo único objetivo es racionalizar la aceptación de la comodidad.",
          ["h3", "5. Exponte más a conocer gente nueva"],
          "¿No te gusta hablar con la gente? Oblígate a hacerlo, aunque el cuerpo no te lo pida. No hace falta que el diálogo sea perfecto, ni que las personas se lleven la mejor imagen de ti. Actuando con naturalidad todo fluirá como debe, y será divertido comprobar con qué facilidad pueden funcionar las interacciones con los demás si no se piensa muy bien lo que es está diciendo.",
          ["h3", "6. Haz que tus amigos y familia cooperen"],
          "Si las personas de tu entorno cercano saben que quieres salir de tu zona de confort, te ayudarán a lograrlo y quizás te preparan alguna «sorpresa». Del mismo modo, cuando tomes la iniciativa y emprendas proyectos nuevos y excitantes, te apoyarán y, probablemente, te mostrarán signos de apoyo o admiración, lo cual te servirá como refuerzo.",
          ["h3", "7. Dale una oportunidad a tu faceta espiritual"],
          "¿Conoces los beneficios de la meditación o de la filosofía Mindfulness? Hay hábitos que consiguen mejorar nuestro estado de ánimo y nos liberan de muchas creencias que nos anclan en nuestra zona de confort. Desarrollar un yo espiritual es una de las maneras más prácticas de conseguir un bienestar emocional capaz de acabar con la vida rutinaria. Puedes empezar por leer esta recopilación de frases budistas, tal vez te inspiran.",
        ],
        autor: "Jonathan García-allen",
        source:
          "https://psicologiaymente.com/coach/salir-zona-de-confort-claves",
      },
      {
        photo: "6",
        title: "¿En tu familia practican la doble moral?",
        shortText:
          "Hablamos de doble moral cuando existe una incongruencia entre lo que pensamos, decimos y hacemos. En el interior de cada familia es importante hacer conciencia y […]",
        link: "en-tu-familia-practican-la-doble-moral",
        fullText: [
          "Hablamos de doble moral cuando existe una incongruencia entre lo que pensamos, decimos y hacemos. En el interior de cada familia es importante hacer conciencia y determinar si los valores en los que decimos creer son una realidad que llevamos a la práctica, tanto en nuestros actos públicos como privados. Y es que, es justo del comportamiento de los padres y demás figuras adultas relevantes en la vida de los niños de donde ellos adquieren su más significativo aprendizaje.",
          "Es importante tener en cuenta que para un niño nunca va a ser suficiente lo que aprenda sobre valores en la escuela, pues la educación de alto impacto, en cuanto a profundidad emocional y a sustentabilidad, provendrá de sus figuras de autoridad más representativas y cercanas, es decir, los padres, y en menor medida los abuelos, tíos y primos mayores.",
          "Si queremos fomentar la paz en nuestras familias, es importante reflexionar si no estamos promoviendo, sea en menor o mayor grado, directa o indirectamente, lo contrario a este valor tan preciado últimamente. Para ello, te recomiendo hacerte estas preguntas que guiarán tu reflexión:",
          [
            "li",
            "¿En mi familia nos referimos a otros miembros (presentes o ausentes) con adjetivos peyorativos?",
          ],
          [
            "li",
            "¿Somos respetuosos al escuchar a algún integrante de la familia hablar sobre sus emociones?",
          ],
          [
            "li",
            "¿Somos tolerantes cuando algún miembro de la familia expresa una opinión contraria o diferente a la de los demás?",
          ],
          [
            "li",
            "¿Somos capaces de convivir en armonía, bajo las normas familiares, al mismo tiempo que respetamos las necesidades individuales de todos (incluyendo los niños)?",
          ],
          [
            "li",
            "¿Somos lo suficientemente maduros para expresar nuestro enojo haciéndonos responsables de esta emoción de una manera saludable, sin agredir física ni verbalmente a otros miembros?",
          ],
          [
            "li",
            "¿Usamos la burla, el sarcasmo o los insultos para criticar o humillar a algún miembro de la familia por algún defecto físico o de carácter, o por haber cometido alguna falta?",
          ],
          [
            "li",
            "¿Se promueven las alianzas entre algunos miembros para planear “ataques” a otros miembros?",
          ],
          [
            "li",
            "¿Somos capaces de hablar abierta y personalmente sobre los conflictos que nos aquejan?",
          ],
          [
            "li",
            "¿Tenemos realmente la intención de resolver nuestras diferencias familiares cuando discutimos?",
          ],
          [
            "li",
            "¿Podemos sentirnos satisfechos, en un estado de armonía familiar, y permitirnos vivir en paz cuando no hay conflictos?",
          ],
          [
            "li",
            "¿Lo que exijo de los demás integrantes de la familia, en términos de respeto, tolerancia y dignidad, es lo mismo que doy (incluyendo a mi pareja)?",
          ],
          "Una vez que hayamos respondido estas preguntas podremos darnos cuenta con mayor claridad en qué aspectos podríamos estar teniendo una doble moral familiar en cuanto al valor de la paz, y hacer algo al respecto si así lo deseamos. Finalmente, no solo somos responsables de lo que hacemos sino de lo que dejamos de hacer.",
          ["b", "Por Elba Quintanilla, psicoterapeuta."],
        ],
        autor: "Elba Quintanilla",
        source:
          "https://psicologiaparaninos.com/2015/11/en-tu-familia-practican-la-doble-moral/",
      },
    ],
  },
  questions: {
    portada: "Preguntas",
    faq: [
      {
        question: "¿Cuándo consultar?",
        answer:
          "Todos tenemos una versión de cómo solucionar los problemas que se nos presentan, cuando esta versión deja de ser eficaz, los problemas comienzan a convertirse en obstáculo. Es en este momento que se hace necesario pedir ayuda.",
      },
      {
        question: "¿En qué consiste la primera entrevista con el profesional?",
        answer:
          "En la primera entrevista de admisión el paciente podrá poner en palabras aquello que lo aqueja, es decir su sufrimiento o malestar individual, de pareja o familiar. En esta entrevista también, se acordará con el profesional los honorarios, los horarios y frecuencia del tratamiento psicológico.",
      },
      {
        question: "¿Cómo realizar una terapia online?",
        answer:
          "Con las nuevas herramientas de comunicación, se puede realizar una terapia desde cualquier lugar teniendo un dispositivo móvil, computador o tablet. De esta manera, se accede a la sesión por medio de videollamada de whatsapp, zoom, meet, skype o cualquier otra herramienta.",
      },
      {
        question: "¿Cuál es la duración y frecuencia del tratamiento?",
        answer:
          "El tratamiento psicológico es un espacio de ayuda, escucha y reflexión que le permitirá producir cambios y aliviar su sufrimiento. La duración del mismo no está estipulada de antemano, sino que será en relación a la evolución de cada paciente.",
      },
      {
        question: "¿Cuáles son los lugares de atención?",
        answer:
          "La Red de Psicólogos de la UBA se encuentra conformada por un equipo de profesionales Psicólogos que disponen de consultorios en Capital Federal y Gran Buenos Aires. Zonas Capital Federal: Palermo, Belgrano, Caballito y Congreso. Zona Oeste: Morón. Zona Norte: Villa Adelina. Zona Sur: Lanús.",
      },
    ],
  },
  online: {
    portada: "Terapia online",
    title: "Nuestra ayuda llega hasta donde estés",
    text1:
      "¡Tu bienestar no tiene fronteras! Ofrecemos atención psicológica virtual a pacientes de habla hispana en todo el mundo. A través de videoconferencias seguras y confidenciales, brindamos terapia individualizada y de calidad adaptada a tus necesidades. Ya sea que te encuentres en América Latina, España o cualquier otro lugar, nuestro equipo de profesionales está disponible para escucharte, apoyarte y ayudarte a encontrar el equilibrio emocional que buscas. No importa la distancia, estamos conectados para tu consulta.",
  },
};
